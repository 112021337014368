'use client'
import React from 'react';
import './loader.css';
import { BeatLoader } from 'react-spinners';

function Loader() {
  return (
    <div className="full-screen-loader">
      <BeatLoader color="#36d7b7" />
    </div>
  );
}

export default Loader;